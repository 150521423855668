
.setup-defaults() {
	@cdn0: '';
	@cdn1: '';
	@cdn2: '';
	@cdn3: '';
}


.setup-defaults();


.about-photo-inset-picture img {
	width: 114.5px;
}

.ready-to-load-img .about-photo-inset-picture-background, .ready-to-load-img .about-photo-inset-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/about-photo-inset.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/about-photo-inset.webp");
	}
	@media (max-width: 57.25px) {

		background-image: url("@{cdn0}/img/about-photo-inset-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/about-photo-inset-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/about-photo-inset-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/about-photo-inset-2x.webp");
		}
		
		@media (max-width: 57.25px) {

			background-image: url("@{cdn0}/img/about-photo-inset.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/about-photo-inset.webp");
			}
		}
	}
}

.about-photo-inset-picture-background-ratio, .about-photo-inset-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.add-item-tan-picture img {
	width: 19px;
}

.ready-to-load-img .add-item-tan-picture-background, .ready-to-load-img .add-item-tan-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/add-item-tan.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/add-item-tan.webp");
	}
	@media (max-width: 9.5px) {

		background-image: url("@{cdn1}/img/add-item-tan-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/add-item-tan-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/add-item-tan-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/add-item-tan-2x.webp");
		}
		
		@media (max-width: 9.5px) {

			background-image: url("@{cdn1}/img/add-item-tan.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/add-item-tan.webp");
			}
		}
	}
}

.add-item-tan-picture-background-ratio, .add-item-tan-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.add-item-picture img {
	width: 19px;
}

.ready-to-load-img .add-item-picture-background, .ready-to-load-img .add-item-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/add-item.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/add-item.webp");
	}
	@media (max-width: 9.5px) {

		background-image: url("@{cdn2}/img/add-item-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/add-item-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/add-item-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/add-item-2x.webp");
		}
		
		@media (max-width: 9.5px) {

			background-image: url("@{cdn2}/img/add-item.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/add-item.webp");
			}
		}
	}
}

.add-item-picture-background-ratio, .add-item-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.artist-info-tan-picture img {
	width: 15px;
}

.ready-to-load-img .artist-info-tan-picture-background, .ready-to-load-img .artist-info-tan-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/artist-info-tan.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/artist-info-tan.webp");
	}
	@media (max-width: 7.5px) {

		background-image: url("@{cdn3}/img/artist-info-tan-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/artist-info-tan-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/artist-info-tan-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/artist-info-tan-2x.webp");
		}
		
		@media (max-width: 7.5px) {

			background-image: url("@{cdn3}/img/artist-info-tan.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/artist-info-tan.webp");
			}
		}
	}
}

.artist-info-tan-picture-background-ratio, .artist-info-tan-picture-background-with-ratio {
	.ratio(1.0333333333333334);
	background-position: center center;
	background-size: cover;
}

.artist-info-picture img {
	width: 15px;
}

.ready-to-load-img .artist-info-picture-background, .ready-to-load-img .artist-info-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/artist-info.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/artist-info.webp");
	}
	@media (max-width: 7.5px) {

		background-image: url("@{cdn0}/img/artist-info-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/artist-info-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/artist-info-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/artist-info-2x.webp");
		}
		
		@media (max-width: 7.5px) {

			background-image: url("@{cdn0}/img/artist-info.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/artist-info.webp");
			}
		}
	}
}

.artist-info-picture-background-ratio, .artist-info-picture-background-with-ratio {
	.ratio(1.0333333333333334);
	background-position: center center;
	background-size: cover;
}

.artist-portal-picture img {
	width: 247px;
}

.ready-to-load-img .artist-portal-picture-background, .ready-to-load-img .artist-portal-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/artist-portal.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/artist-portal.webp");
	}
	@media (max-width: 123.5px) {

		background-image: url("@{cdn1}/img/artist-portal-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/artist-portal-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/artist-portal-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/artist-portal-2x.webp");
		}
		
		@media (max-width: 123.5px) {

			background-image: url("@{cdn1}/img/artist-portal.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/artist-portal.webp");
			}
		}
	}
}

.artist-portal-picture-background-ratio, .artist-portal-picture-background-with-ratio {
	.ratio(0.12753036437246965);
	background-position: center center;
	background-size: cover;
}

.bronze-category-picture img {
	width: 366.5px;
}

.ready-to-load-img .bronze-category-picture-background, .ready-to-load-img .bronze-category-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/bronze-category.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/bronze-category.webp");
	}
	@media (max-width: 183.25px) {

		background-image: url("@{cdn2}/img/bronze-category-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/bronze-category-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/bronze-category-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/bronze-category-2x.webp");
		}
		
		@media (max-width: 183.25px) {

			background-image: url("@{cdn2}/img/bronze-category.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/bronze-category.webp");
			}
		}
	}
}

.bronze-category-picture-background-ratio, .bronze-category-picture-background-with-ratio {
	.ratio(1.4979536152796726);
	background-position: center center;
	background-size: cover;
}

.cart-icon-white-picture img {
	width: 35px;
}

.ready-to-load-img .cart-icon-white-picture-background, .ready-to-load-img .cart-icon-white-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/cart-icon-white.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/cart-icon-white.webp");
	}
	@media (max-width: 17.5px) {

		background-image: url("@{cdn3}/img/cart-icon-white-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/cart-icon-white-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/cart-icon-white-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/cart-icon-white-2x.webp");
		}
		
		@media (max-width: 17.5px) {

			background-image: url("@{cdn3}/img/cart-icon-white.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/cart-icon-white.webp");
			}
		}
	}
}

.cart-icon-white-picture-background-ratio, .cart-icon-white-picture-background-with-ratio {
	.ratio(0.8857142857142857);
	background-position: center center;
	background-size: cover;
}

.cart-icon-picture img {
	width: 35px;
}

.ready-to-load-img .cart-icon-picture-background, .ready-to-load-img .cart-icon-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/cart-icon.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/cart-icon.webp");
	}
	@media (max-width: 17.5px) {

		background-image: url("@{cdn0}/img/cart-icon-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/cart-icon-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/cart-icon-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/cart-icon-2x.webp");
		}
		
		@media (max-width: 17.5px) {

			background-image: url("@{cdn0}/img/cart-icon.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/cart-icon.webp");
			}
		}
	}
}

.cart-icon-picture-background-ratio, .cart-icon-picture-background-with-ratio {
	.ratio(0.8857142857142857);
	background-position: center center;
	background-size: cover;
}

.carvings-category-picture img {
	width: 367px;
}

.ready-to-load-img .carvings-category-picture-background, .ready-to-load-img .carvings-category-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/carvings-category.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/carvings-category.webp");
	}
	@media (max-width: 183.5px) {

		background-image: url("@{cdn1}/img/carvings-category-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/carvings-category-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/carvings-category-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/carvings-category-2x.webp");
		}
		
		@media (max-width: 183.5px) {

			background-image: url("@{cdn1}/img/carvings-category.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/carvings-category.webp");
			}
		}
	}
}

.carvings-category-picture-background-ratio, .carvings-category-picture-background-with-ratio {
	.ratio(1.5);
	background-position: center center;
	background-size: cover;
}

.contact-box-logo-picture img {
	width: 208px;
}

.ready-to-load-img .contact-box-logo-picture-background, .ready-to-load-img .contact-box-logo-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/contact-box-logo.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/contact-box-logo.webp");
	}
	@media (max-width: 104px) {

		background-image: url("@{cdn2}/img/contact-box-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/contact-box-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/contact-box-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/contact-box-logo-2x.webp");
		}
		
		@media (max-width: 104px) {

			background-image: url("@{cdn2}/img/contact-box-logo.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/contact-box-logo.webp");
			}
		}
	}
}

.contact-box-logo-picture-background-ratio, .contact-box-logo-picture-background-with-ratio {
	.ratio(0.31490384615384615);
	background-position: center center;
	background-size: cover;
}

.email-signup-background-2-picture img {
	width: 1920px;
}

.ready-to-load-img .email-signup-background-2-picture-background, .ready-to-load-img .email-signup-background-2-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/email-signup-background-2.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/email-signup-background-2.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/email-signup-background-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/email-signup-background-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/email-signup-background-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/email-signup-background-2-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/email-signup-background-2.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/email-signup-background-2.webp");
			}
		}
	}
}

.email-signup-background-2-picture-background-ratio, .email-signup-background-2-picture-background-with-ratio {
	.ratio(0.2390625);
	background-position: center center;
	background-size: cover;
}

.email-signup-background-picture img {
	width: 1920px;
}

.ready-to-load-img .email-signup-background-picture-background, .ready-to-load-img .email-signup-background-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/email-signup-background.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/email-signup-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/email-signup-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/email-signup-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/email-signup-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/email-signup-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/email-signup-background.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/email-signup-background.webp");
			}
		}
	}
}

.email-signup-background-picture-background-ratio, .email-signup-background-picture-background-with-ratio {
	.ratio(0.4231770833333333);
	background-position: center center;
	background-size: cover;
}

.facebook-icon-picture img {
	width: 30px;
}

.ready-to-load-img .facebook-icon-picture-background, .ready-to-load-img .facebook-icon-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/facebook-icon.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/facebook-icon.webp");
	}
	@media (max-width: 15px) {

		background-image: url("@{cdn1}/img/facebook-icon-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/facebook-icon-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/facebook-icon-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/facebook-icon-2x.webp");
		}
		
		@media (max-width: 15px) {

			background-image: url("@{cdn1}/img/facebook-icon.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/facebook-icon.webp");
			}
		}
	}
}

.facebook-icon-picture-background-ratio, .facebook-icon-picture-background-with-ratio {
	.ratio(1.0166666666666666);
	background-position: center center;
	background-size: cover;
}

.facebook-small-picture img {
	width: 14.5px;
}

.ready-to-load-img .facebook-small-picture-background, .ready-to-load-img .facebook-small-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/facebook-small.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/facebook-small.webp");
	}
	@media (max-width: 7.25px) {

		background-image: url("@{cdn2}/img/facebook-small-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/facebook-small-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/facebook-small-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/facebook-small-2x.webp");
		}
		
		@media (max-width: 7.25px) {

			background-image: url("@{cdn2}/img/facebook-small.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/facebook-small.webp");
			}
		}
	}
}

.facebook-small-picture-background-ratio, .facebook-small-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.footer-mark-picture img {
	width: 185px;
}

.ready-to-load-img .footer-mark-picture-background, .ready-to-load-img .footer-mark-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/footer-mark.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/footer-mark.webp");
	}
	@media (max-width: 92.5px) {

		background-image: url("@{cdn3}/img/footer-mark-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/footer-mark-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/footer-mark-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/footer-mark-2x.webp");
		}
		
		@media (max-width: 92.5px) {

			background-image: url("@{cdn3}/img/footer-mark.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/footer-mark.webp");
			}
		}
	}
}

.footer-mark-picture-background-ratio, .footer-mark-picture-background-with-ratio {
	.ratio(1.2162162162162162);
	background-position: center center;
	background-size: cover;
}

.gallery-mega-picture img {
	width: 1920px;
}

.ready-to-load-img .gallery-mega-picture-background, .ready-to-load-img .gallery-mega-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/gallery-mega.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/gallery-mega.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/gallery-mega-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/gallery-mega-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/gallery-mega-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/gallery-mega-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/gallery-mega.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/gallery-mega.webp");
			}
		}
	}
}

.gallery-mega-picture-background-ratio, .gallery-mega-picture-background-with-ratio {
	.ratio(0.46432291666666664);
	background-position: center center;
	background-size: cover;
}

.gift-ideas-mega-picture img {
	width: 1920px;
}

.ready-to-load-img .gift-ideas-mega-picture-background, .ready-to-load-img .gift-ideas-mega-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/gift-ideas-mega.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/gift-ideas-mega.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/gift-ideas-mega-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/gift-ideas-mega-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/gift-ideas-mega-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/gift-ideas-mega-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/gift-ideas-mega.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/gift-ideas-mega.webp");
			}
		}
	}
}

.gift-ideas-mega-picture-background-ratio, .gift-ideas-mega-picture-background-with-ratio {
	.ratio(0.309375);
	background-position: center center;
	background-size: cover;
}

.gifts-category-picture img {
	width: 366.5px;
}

.ready-to-load-img .gifts-category-picture-background, .ready-to-load-img .gifts-category-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/gifts-category.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/gifts-category.webp");
	}
	@media (max-width: 183.25px) {

		background-image: url("@{cdn2}/img/gifts-category-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/gifts-category-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/gifts-category-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/gifts-category-2x.webp");
		}
		
		@media (max-width: 183.25px) {

			background-image: url("@{cdn2}/img/gifts-category.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/gifts-category.webp");
			}
		}
	}
}

.gifts-category-picture-background-ratio, .gifts-category-picture-background-with-ratio {
	.ratio(1.5020463847203274);
	background-position: center center;
	background-size: cover;
}

.gold-star-picture img {
	width: 31px;
}

.ready-to-load-img .gold-star-picture-background, .ready-to-load-img .gold-star-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/gold-star.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/gold-star.webp");
	}
	@media (max-width: 15.5px) {

		background-image: url("@{cdn3}/img/gold-star-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/gold-star-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/gold-star-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/gold-star-2x.webp");
		}
		
		@media (max-width: 15.5px) {

			background-image: url("@{cdn3}/img/gold-star.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/gold-star.webp");
			}
		}
	}
}

.gold-star-picture-background-ratio, .gold-star-picture-background-with-ratio {
	.ratio(1.0483870967741935);
	background-position: center center;
	background-size: cover;
}

.horse-1-picture img {
	width: 173.5px;
}

.ready-to-load-img .horse-1-picture-background, .ready-to-load-img .horse-1-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/horse-1.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/horse-1.webp");
	}
	@media (max-width: 86.75px) {

		background-image: url("@{cdn0}/img/horse-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/horse-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/horse-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/horse-1-2x.webp");
		}
		
		@media (max-width: 86.75px) {

			background-image: url("@{cdn0}/img/horse-1.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/horse-1.webp");
			}
		}
	}
}

.horse-1-picture-background-ratio, .horse-1-picture-background-with-ratio {
	.ratio(0.7636887608069164);
	background-position: center center;
	background-size: cover;
}

.horse-2-picture img {
	width: 174px;
}

.ready-to-load-img .horse-2-picture-background, .ready-to-load-img .horse-2-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/horse-2.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/horse-2.webp");
	}
	@media (max-width: 87px) {

		background-image: url("@{cdn1}/img/horse-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/horse-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/horse-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/horse-2-2x.webp");
		}
		
		@media (max-width: 87px) {

			background-image: url("@{cdn1}/img/horse-2.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/horse-2.webp");
			}
		}
	}
}

.horse-2-picture-background-ratio, .horse-2-picture-background-with-ratio {
	.ratio(0.7614942528735632);
	background-position: center center;
	background-size: cover;
}

.horse-large-picture img {
	width: 699.5px;
}

.ready-to-load-img .horse-large-picture-background, .ready-to-load-img .horse-large-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/horse-large.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/horse-large.webp");
	}
	@media (max-width: 349.75px) {

		background-image: url("@{cdn2}/img/horse-large-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/horse-large-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/horse-large-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/horse-large-2x.webp");
		}
		
		@media (max-width: 349.75px) {

			background-image: url("@{cdn2}/img/horse-large.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/horse-large.webp");
			}
		}
	}
}

.horse-large-picture-background-ratio, .horse-large-picture-background-with-ratio {
	.ratio(0.7634024303073624);
	background-position: center center;
	background-size: cover;
}

.inner-header-background-picture img {
	width: 1920px;
}

.ready-to-load-img .inner-header-background-picture-background, .ready-to-load-img .inner-header-background-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/inner-header-background.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/inner-header-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/inner-header-background-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/inner-header-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/inner-header-background-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/inner-header-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/inner-header-background.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/inner-header-background.webp");
			}
		}
	}
}

.inner-header-background-picture-background-ratio, .inner-header-background-picture-background-with-ratio {
	.ratio(0.07083333333333333);
	background-position: center center;
	background-size: cover;
}

.inner-header-facebook-picture img {
	width: 30px;
}

.ready-to-load-img .inner-header-facebook-picture-background, .ready-to-load-img .inner-header-facebook-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/inner-header-facebook.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/inner-header-facebook.webp");
	}
	@media (max-width: 15px) {

		background-image: url("@{cdn0}/img/inner-header-facebook-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/inner-header-facebook-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/inner-header-facebook-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/inner-header-facebook-2x.webp");
		}
		
		@media (max-width: 15px) {

			background-image: url("@{cdn0}/img/inner-header-facebook.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/inner-header-facebook.webp");
			}
		}
	}
}

.inner-header-facebook-picture-background-ratio, .inner-header-facebook-picture-background-with-ratio {
	.ratio(1.0166666666666666);
	background-position: center center;
	background-size: cover;
}

.inner-header-logo-black-picture img {
	width: 208px;
}

.ready-to-load-img .inner-header-logo-black-picture-background, .ready-to-load-img .inner-header-logo-black-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/inner-header-logo-black.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/inner-header-logo-black.webp");
	}
	@media (max-width: 104px) {

		background-image: url("@{cdn1}/img/inner-header-logo-black-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/inner-header-logo-black-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/inner-header-logo-black-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/inner-header-logo-black-2x.webp");
		}
		
		@media (max-width: 104px) {

			background-image: url("@{cdn1}/img/inner-header-logo-black.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/inner-header-logo-black.webp");
			}
		}
	}
}

.inner-header-logo-black-picture-background-ratio, .inner-header-logo-black-picture-background-with-ratio {
	.ratio(0.31490384615384615);
	background-position: center center;
	background-size: cover;
}

.inner-header-logo-picture img {
	width: 208px;
}

.ready-to-load-img .inner-header-logo-picture-background, .ready-to-load-img .inner-header-logo-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/inner-header-logo.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/inner-header-logo.webp");
	}
	@media (max-width: 104px) {

		background-image: url("@{cdn2}/img/inner-header-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/inner-header-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/inner-header-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/inner-header-logo-2x.webp");
		}
		
		@media (max-width: 104px) {

			background-image: url("@{cdn2}/img/inner-header-logo.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/inner-header-logo.webp");
			}
		}
	}
}

.inner-header-logo-picture-background-ratio, .inner-header-logo-picture-background-with-ratio {
	.ratio(0.31490384615384615);
	background-position: center center;
	background-size: cover;
}

.inventory-status-tan-picture img {
	width: 15px;
}

.ready-to-load-img .inventory-status-tan-picture-background, .ready-to-load-img .inventory-status-tan-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/inventory-status-tan.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/inventory-status-tan.webp");
	}
	@media (max-width: 7.5px) {

		background-image: url("@{cdn3}/img/inventory-status-tan-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/inventory-status-tan-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/inventory-status-tan-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/inventory-status-tan-2x.webp");
		}
		
		@media (max-width: 7.5px) {

			background-image: url("@{cdn3}/img/inventory-status-tan.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/inventory-status-tan.webp");
			}
		}
	}
}

.inventory-status-tan-picture-background-ratio, .inventory-status-tan-picture-background-with-ratio {
	.ratio(0.8666666666666667);
	background-position: center center;
	background-size: cover;
}

.inventory-status-picture img {
	width: 15px;
}

.ready-to-load-img .inventory-status-picture-background, .ready-to-load-img .inventory-status-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/inventory-status.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/inventory-status.webp");
	}
	@media (max-width: 7.5px) {

		background-image: url("@{cdn0}/img/inventory-status-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/inventory-status-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/inventory-status-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/inventory-status-2x.webp");
		}
		
		@media (max-width: 7.5px) {

			background-image: url("@{cdn0}/img/inventory-status.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/inventory-status.webp");
			}
		}
	}
}

.inventory-status-picture-background-ratio, .inventory-status-picture-background-with-ratio {
	.ratio(0.8666666666666667);
	background-position: center center;
	background-size: cover;
}

.james-krom-banner-1-picture img {
	width: 1920px;
}

.ready-to-load-img .james-krom-banner-1-picture-background, .ready-to-load-img .james-krom-banner-1-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/james-krom-banner-1.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/james-krom-banner-1.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/james-krom-banner-1-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/james-krom-banner-1-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/james-krom-banner-1-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/james-krom-banner-1-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/james-krom-banner-1.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/james-krom-banner-1.webp");
			}
		}
	}
}

.james-krom-banner-1-picture-background-ratio, .james-krom-banner-1-picture-background-with-ratio {
	.ratio(0.4705729166666667);
	background-position: center center;
	background-size: cover;
}

.james-krom-banner-2-picture img {
	width: 1920px;
}

.ready-to-load-img .james-krom-banner-2-picture-background, .ready-to-load-img .james-krom-banner-2-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/james-krom-banner-2.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/james-krom-banner-2.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/james-krom-banner-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/james-krom-banner-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/james-krom-banner-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/james-krom-banner-2-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/james-krom-banner-2.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/james-krom-banner-2.webp");
			}
		}
	}
}

.james-krom-banner-2-picture-background-ratio, .james-krom-banner-2-picture-background-with-ratio {
	.ratio(0.4705729166666667);
	background-position: center center;
	background-size: cover;
}

.james-krom-banner-4-picture img {
	width: 1920px;
}

.ready-to-load-img .james-krom-banner-4-picture-background, .ready-to-load-img .james-krom-banner-4-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/james-krom-banner-4.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/james-krom-banner-4.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/james-krom-banner-4-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/james-krom-banner-4-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/james-krom-banner-4-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/james-krom-banner-4-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/james-krom-banner-4.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/james-krom-banner-4.webp");
			}
		}
	}
}

.james-krom-banner-4-picture-background-ratio, .james-krom-banner-4-picture-background-with-ratio {
	.ratio(0.4705729166666667);
	background-position: center center;
	background-size: cover;
}

.james-krom-banner-5-picture img {
	width: 1920px;
}

.ready-to-load-img .james-krom-banner-5-picture-background, .ready-to-load-img .james-krom-banner-5-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/james-krom-banner-5.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/james-krom-banner-5.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn0}/img/james-krom-banner-5-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/james-krom-banner-5-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/james-krom-banner-5-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/james-krom-banner-5-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn0}/img/james-krom-banner-5.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/james-krom-banner-5.webp");
			}
		}
	}
}

.james-krom-banner-5-picture-background-ratio, .james-krom-banner-5-picture-background-with-ratio {
	.ratio(0.4705729166666667);
	background-position: center center;
	background-size: cover;
}

.jewelry-category-picture img {
	width: 367px;
}

.ready-to-load-img .jewelry-category-picture-background, .ready-to-load-img .jewelry-category-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/jewelry-category.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/jewelry-category.webp");
	}
	@media (max-width: 183.5px) {

		background-image: url("@{cdn1}/img/jewelry-category-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/jewelry-category-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/jewelry-category-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/jewelry-category-2x.webp");
		}
		
		@media (max-width: 183.5px) {

			background-image: url("@{cdn1}/img/jewelry-category.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/jewelry-category.webp");
			}
		}
	}
}

.jewelry-category-picture-background-ratio, .jewelry-category-picture-background-with-ratio {
	.ratio(1.5);
	background-position: center center;
	background-size: cover;
}

.krom-logo-picture img {
	width: 394px;
}

.ready-to-load-img .krom-logo-picture-background, .ready-to-load-img .krom-logo-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/krom-logo.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/krom-logo.webp");
	}
	@media (max-width: 197px) {

		background-image: url("@{cdn2}/img/krom-logo-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/krom-logo-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/krom-logo-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/krom-logo-2x.webp");
		}
		
		@media (max-width: 197px) {

			background-image: url("@{cdn2}/img/krom-logo.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/krom-logo.webp");
			}
		}
	}
}

.krom-logo-picture-background-ratio, .krom-logo-picture-background-with-ratio {
	.ratio(0.3096446700507614);
	background-position: center center;
	background-size: cover;
}

.location-attraction-map-picture img {
	width: 634px;
}

.ready-to-load-img .location-attraction-map-picture-background, .ready-to-load-img .location-attraction-map-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/location-attraction-map.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/location-attraction-map.webp");
	}
	@media (max-width: 317px) {

		background-image: url("@{cdn3}/img/location-attraction-map-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/location-attraction-map-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/location-attraction-map-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/location-attraction-map-2x.webp");
		}
		
		@media (max-width: 317px) {

			background-image: url("@{cdn3}/img/location-attraction-map.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/location-attraction-map.webp");
			}
		}
	}
}

.location-attraction-map-picture-background-ratio, .location-attraction-map-picture-background-with-ratio {
	.ratio(1.027602523659306);
	background-position: center center;
	background-size: cover;
}

.location-map-2-picture img {
	width: 1514px;
}

.ready-to-load-img .location-map-2-picture-background, .ready-to-load-img .location-map-2-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/location-map-2.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/location-map-2.webp");
	}
	@media (max-width: 757px) {

		background-image: url("@{cdn0}/img/location-map-2-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/location-map-2-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/location-map-2-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/location-map-2-2x.webp");
		}
		
		@media (max-width: 757px) {

			background-image: url("@{cdn0}/img/location-map-2.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/location-map-2.webp");
			}
		}
	}
}

.location-map-2-picture-background-ratio, .location-map-2-picture-background-with-ratio {
	.ratio(0.25264200792602376);
	background-position: center center;
	background-size: cover;
}

.location-map-picture img {
	width: 1920px;
}

.ready-to-load-img .location-map-picture-background, .ready-to-load-img .location-map-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/location-map.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/location-map.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn1}/img/location-map-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/location-map-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/location-map-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/location-map-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn1}/img/location-map.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/location-map.webp");
			}
		}
	}
}

.location-map-picture-background-ratio, .location-map-picture-background-with-ratio {
	.ratio(0.19791666666666666);
	background-position: center center;
	background-size: cover;
}

.location-marker-picture img {
	width: 13.5px;
}

.ready-to-load-img .location-marker-picture-background, .ready-to-load-img .location-marker-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/location-marker.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/location-marker.webp");
	}
	@media (max-width: 6.75px) {

		background-image: url("@{cdn2}/img/location-marker-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/location-marker-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/location-marker-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/location-marker-2x.webp");
		}
		
		@media (max-width: 6.75px) {

			background-image: url("@{cdn2}/img/location-marker.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/location-marker.webp");
			}
		}
	}
}

.location-marker-picture-background-ratio, .location-marker-picture-background-with-ratio {
	.ratio(1.3333333333333333);
	background-position: center center;
	background-size: cover;
}

.mailing-list-background-picture img {
	width: 1920px;
}

.ready-to-load-img .mailing-list-background-picture-background, .ready-to-load-img .mailing-list-background-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/mailing-list-background.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/mailing-list-background.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn3}/img/mailing-list-background-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/mailing-list-background-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/mailing-list-background-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/mailing-list-background-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn3}/img/mailing-list-background.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/mailing-list-background.webp");
			}
		}
	}
}

.mailing-list-background-picture-background-ratio, .mailing-list-background-picture-background-with-ratio {
	.ratio(0.15989583333333332);
	background-position: center center;
	background-size: cover;
}

.map-with-notables-picture img {
	width: 697.5px;
}

.ready-to-load-img .map-with-notables-picture-background, .ready-to-load-img .map-with-notables-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/map-with-notables.jpg");
	
	.webp & {
		background-image: url("@{cdn0}/img/map-with-notables.webp");
	}
	@media (max-width: 348.75px) {

		background-image: url("@{cdn0}/img/map-with-notables-half.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/map-with-notables-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/map-with-notables-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn0}/img/map-with-notables-2x.webp");
		}
		
		@media (max-width: 348.75px) {

			background-image: url("@{cdn0}/img/map-with-notables.jpg");
			
			.webp & {
				background-image: url("@{cdn0}/img/map-with-notables.webp");
			}
		}
	}
}

.map-with-notables-picture-background-ratio, .map-with-notables-picture-background-with-ratio {
	.ratio(0.6774193548387096);
	background-position: center center;
	background-size: cover;
}

.paintings-category-picture img {
	width: 367px;
}

.ready-to-load-img .paintings-category-picture-background, .ready-to-load-img .paintings-category-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/paintings-category.jpg");
	
	.webp & {
		background-image: url("@{cdn1}/img/paintings-category.webp");
	}
	@media (max-width: 183.5px) {

		background-image: url("@{cdn1}/img/paintings-category-half.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/paintings-category-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/paintings-category-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn1}/img/paintings-category-2x.webp");
		}
		
		@media (max-width: 183.5px) {

			background-image: url("@{cdn1}/img/paintings-category.jpg");
			
			.webp & {
				background-image: url("@{cdn1}/img/paintings-category.webp");
			}
		}
	}
}

.paintings-category-picture-background-ratio, .paintings-category-picture-background-with-ratio {
	.ratio(1.4959128065395095);
	background-position: center center;
	background-size: cover;
}

.red-arrow-picture img {
	width: 359px;
}

.ready-to-load-img .red-arrow-picture-background, .ready-to-load-img .red-arrow-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/red-arrow.png");
	
	.webp & {
		background-image: url("@{cdn2}/img/red-arrow.webp");
	}
	@media (max-width: 179.5px) {

		background-image: url("@{cdn2}/img/red-arrow-half.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/red-arrow-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/red-arrow-2x.png");
		
		.webp & {
			background-image: url("@{cdn2}/img/red-arrow-2x.webp");
		}
		
		@media (max-width: 179.5px) {

			background-image: url("@{cdn2}/img/red-arrow.png");
			
			.webp & {
				background-image: url("@{cdn2}/img/red-arrow.webp");
			}
		}
	}
}

.red-arrow-picture-background-ratio, .red-arrow-picture-background-with-ratio {
	.ratio(0.2395543175487465);
	background-position: center center;
	background-size: cover;
}

.sales-summary-tan-picture img {
	width: 8.5px;
}

.ready-to-load-img .sales-summary-tan-picture-background, .ready-to-load-img .sales-summary-tan-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/sales-summary-tan.png");
	
	.webp & {
		background-image: url("@{cdn3}/img/sales-summary-tan.webp");
	}
	@media (max-width: 4.25px) {

		background-image: url("@{cdn3}/img/sales-summary-tan-half.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/sales-summary-tan-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/sales-summary-tan-2x.png");
		
		.webp & {
			background-image: url("@{cdn3}/img/sales-summary-tan-2x.webp");
		}
		
		@media (max-width: 4.25px) {

			background-image: url("@{cdn3}/img/sales-summary-tan.png");
			
			.webp & {
				background-image: url("@{cdn3}/img/sales-summary-tan.webp");
			}
		}
	}
}

.sales-summary-tan-picture-background-ratio, .sales-summary-tan-picture-background-with-ratio {
	.ratio(1.8235294117647058);
	background-position: center center;
	background-size: cover;
}

.sales-summary-picture img {
	width: 8.5px;
}

.ready-to-load-img .sales-summary-picture-background, .ready-to-load-img .sales-summary-picture-background-with-ratio {
	background-image: url("@{cdn0}/img/sales-summary.png");
	
	.webp & {
		background-image: url("@{cdn0}/img/sales-summary.webp");
	}
	@media (max-width: 4.25px) {

		background-image: url("@{cdn0}/img/sales-summary-half.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/sales-summary-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn0}/img/sales-summary-2x.png");
		
		.webp & {
			background-image: url("@{cdn0}/img/sales-summary-2x.webp");
		}
		
		@media (max-width: 4.25px) {

			background-image: url("@{cdn0}/img/sales-summary.png");
			
			.webp & {
				background-image: url("@{cdn0}/img/sales-summary.webp");
			}
		}
	}
}

.sales-summary-picture-background-ratio, .sales-summary-picture-background-with-ratio {
	.ratio(1.8235294117647058);
	background-position: center center;
	background-size: cover;
}

.search-icon-picture img {
	width: 27.5px;
}

.ready-to-load-img .search-icon-picture-background, .ready-to-load-img .search-icon-picture-background-with-ratio {
	background-image: url("@{cdn1}/img/search-icon.png");
	
	.webp & {
		background-image: url("@{cdn1}/img/search-icon.webp");
	}
	@media (max-width: 13.75px) {

		background-image: url("@{cdn1}/img/search-icon-half.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/search-icon-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn1}/img/search-icon-2x.png");
		
		.webp & {
			background-image: url("@{cdn1}/img/search-icon-2x.webp");
		}
		
		@media (max-width: 13.75px) {

			background-image: url("@{cdn1}/img/search-icon.png");
			
			.webp & {
				background-image: url("@{cdn1}/img/search-icon.webp");
			}
		}
	}
}

.search-icon-picture-background-ratio, .search-icon-picture-background-with-ratio {
	.ratio(1);
	background-position: center center;
	background-size: cover;
}

.wolf-header-picture img {
	width: 1920px;
}

.ready-to-load-img .wolf-header-picture-background, .ready-to-load-img .wolf-header-picture-background-with-ratio {
	background-image: url("@{cdn2}/img/wolf-header.jpg");
	
	.webp & {
		background-image: url("@{cdn2}/img/wolf-header.webp");
	}
	@media (max-width: 960px) {

		background-image: url("@{cdn2}/img/wolf-header-half.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/wolf-header-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn2}/img/wolf-header-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn2}/img/wolf-header-2x.webp");
		}
		
		@media (max-width: 960px) {

			background-image: url("@{cdn2}/img/wolf-header.jpg");
			
			.webp & {
				background-image: url("@{cdn2}/img/wolf-header.webp");
			}
		}
	}
}

.wolf-header-picture-background-ratio, .wolf-header-picture-background-with-ratio {
	.ratio(0.09557291666666666);
	background-position: center center;
	background-size: cover;
}

.woodturning-category-picture img {
	width: 366.5px;
}

.ready-to-load-img .woodturning-category-picture-background, .ready-to-load-img .woodturning-category-picture-background-with-ratio {
	background-image: url("@{cdn3}/img/woodturning-category.jpg");
	
	.webp & {
		background-image: url("@{cdn3}/img/woodturning-category.webp");
	}
	@media (max-width: 183.25px) {

		background-image: url("@{cdn3}/img/woodturning-category-half.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/woodturning-category-half.webp");
		}
	}
	@media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi){ 
		background-image: url("@{cdn3}/img/woodturning-category-2x.jpg");
		
		.webp & {
			background-image: url("@{cdn3}/img/woodturning-category-2x.webp");
		}
		
		@media (max-width: 183.25px) {

			background-image: url("@{cdn3}/img/woodturning-category.jpg");
			
			.webp & {
				background-image: url("@{cdn3}/img/woodturning-category.webp");
			}
		}
	}
}

.woodturning-category-picture-background-ratio, .woodturning-category-picture-background-with-ratio {
	.ratio(1.5020463847203274);
	background-position: center center;
	background-size: cover;
}
