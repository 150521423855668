.site-header {
	.slides {
		.ratio(.4);
		position: relative;
		background-color: black;
		

		&:before {
			min-height: 400px;
			box-sizing: border-box;
		}
	}
	
	.slides.vid-holder {
		overflow: hidden;
		&:after {
			content: '\00a0';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 2;
			background-color: rgba(0,0,0,.5);
		}
	}
	
	.slides video {
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		transform: translate(-50%, -50%);
		
	}


	.slides .slide {
		position           : absolute;
		top                : 0;
		bottom             : 0;
		left               : 0;
		box-sizing         : border-box;
		background-position: center center;
		background-repeat  : no-repeat;
		background-size    : cover;
		opacity            : 0;
	}

	.slides .slide:first-of-type {
		width  : 100%;
		z-index: 2;
		opacity: 1;
	}

	.slides.transitions-on .slide {
		transition: 1s;
		width     : 100%;
	}


	.slides .slide.visible {
		opacity: 1;
		z-index: 4;
	}

	.slides .slide.invisible {
		opacity: 0;
	}

	.slides .slide-link {
		position: absolute;
		top     : 0;
		right   : 0;
		bottom  : 0;
		left    : 0;
	}

	.slide {
		.mask {
			position: absolute;
			top     : 0;
			right   : 0;
			bottom  : 0;
			left    : 0;
			.full-back;
			z-index: 1;
		}

		.container {
			position: relative;
			height  : 100%;
			z-index : 2;
		}

		.msg {
			position : absolute;
			top      : 50%;
			transform: translateY(-50%);
			left     : 0;

			h1 {
				.fs(69);
				color      : white;
				line-height: 1.1em;
				margin     : 0;

			}

			h2 {
				.fs(28);
				line-height: 1.3em;
				margin-top : 20px;
			}

			.round-button {
				color: white;
			}

			.desktop-and-smaller( {
					margin-top: 60px;

					h1 {
						.fs(40);
					}

					h2 {
						.fs(20);
					}
				}

			);

			.big-phone-and-smaller( {
					margin-top: 10px;
				}

			)
		}
	}

	.dots {
		position : absolute;
		bottom   : 20px;
		right     : 50px;
		transform: translateX(-50%);
		z-index  : 50;

		@dot-size: 10px;

		.dot {
			width        : @dot-size;
			height       : @dot-size;
			display      : inline-block;
			border-radius: 50%;
			border       : solid 1px white;
			margin       : auto 10px;
			cursor       : pointer;

			&.current {
				background-color: white;
				border          : solid 1px white;
			}
		}
	}

	.container-wrapper {
		padding-left : 0;
		padding-right: 0;

		.slide {
			padding-left : 10px;
			padding-right: 10px;
		}
	}
}


.brown-button {
	display: inline-block;
	background-color: @brown;
	color           : white;
	padding         : .5em 2em;
	font-weight     : 600;
	border          : 0;
	text-decoration : none;
}

.hollow-button {
	.brown-button;
	background-color: transparent;
	border          : solid 2px @tan;
	transition: background-color .3s;
	
	&:hover {
		background-color: @tan;
	}
}
.tan-button {
	.brown-button;
	background-color: @tan;
}

.tan-link {
	.brown-button;
	background-color: @tan;
	text-transform: none !important;
	cursor: pointer;
}

.page {
	.full-pictures {
		img {
			width: 100%;
		}
	}
}

.artwork-layout {
	display: grid;
	grid-template-columns: 1fr 520px;
	grid-template-rows: auto 1fr;
	column-gap: 30px;
	margin: 0px auto 50px auto;
	max-width: 100%;
	
	& > * > p:first-of-type, & > * > h2:first-child {
		margin-top: 0;
	}
	.header {
		padding-top: 20px;
		padding-left: @left-padding;
		
		.logo {
			img {
				filter: grayscale(100%);
			}
		}
	}
	
	.pics {
		padding-top: 20px;
		min-height: 80vh;
		.primary-image {
			position: relative;
			// aspect-ratio: 16/9;
			overflow: hidden;
			height: 100%;
			
			img.artwork {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: contain;
				object-position: center center;
				opacity: 0;
				transition: opacity .3s;
			}
			
			.left, .right {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				padding: 50px;
				cursor: pointer;
				z-index: 2;
				
				img {
					height: 60px;
				}
			}
			
			.left {
				left: 0;
				padding-left: 0px;
			}
			.right {
				right: 0;
				padding-right: 0px;
			}
			
			.back {
				.fs(80);
				position: absolute;
				left: 20px;
				top: 0px;
				z-index: 2;
				color: white;
				text-shadow: 1px 1px 5px black;
				cursor: pointer;
				text-decoration: none;
				.txt {
					.fs(40);
					vertical-align: baseline;
				}
			}
		}
	}
	
	.thumbnails {
		font-size: 0;
		display: none;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 10px;
		margin-top: 20px;

		.thumbnail {
			background-position: center center;
			background-size: cover;
			.ratio(1);
			cursor: pointer;
			
			&:nth-child(n+5) {
				display: none;
			}
		}
	}
	
	@right-padding: 40px;
	@left-padding: 20px;
	
	.artwork-navigation {
		display: flex;
		grid-template-columns: repeat(2, 1fr);
		color: @tan;
		justify-content: space-between;
		padding-right: @right-padding;
		a {
			color: inherit;
		}
		
		.arrow {
			display: inline-block;
			vertical-align: text-bottom;
			font-size: 130%;
			
		}
		
		.artist {
			padding-top: 6px;
		}
	}
	.details {
		padding-right: @right-padding;
		padding-left: @left-padding;
		
		
		h2 {
			.cormorant;
			.fs(49);
			text-transform: uppercase;
			margin-bottom: 0;
			margin-top: 20px;
		}
		
		.artist {
			color: @tan;
			a {
				color: inherit;
			}
		}
		.price {
			margin-top: 15px;
			margin-bottom: 10px;
			font-weight: 300;
			.fs(49);
		}
		.actions {
			margin-bottom: 30px;
			& > * {
				display: inline-block;
				vertical-align: top;
			}
			form {
				display: inline-block;
				margin-right: 20px;
				margin-bottom: 20px;
			}
			
			a {
				text-transform: uppercase;
			}
		}
		
	}
	
	.tablet({
		grid-template-rows: auto 1fr;
		.header {
			grid-row: 1;
			grid-column: 2;
		}
		.pics {
			grid-row: 1 / span 2;
		}

		.details {
			grid-row: 2;
			grid-column: 2;
		}
	});
	
	.desktop-and-smaller({
		grid-template-columns: auto 400px;

	});
	.tablet-and-smaller({
		grid-template-columns: 1fr;
		.details {
			max-width: none;
		}
		.pics {
			min-height: 30vh;
			.primary-image {
				.back {
					.fs(40);
					left: 20px;
					top: -10px;
					text-decoration: none;
					.txt {
						.fs(20);
					}
				}

			}
		}
	})
}

.breadcrumbs {
	// padding: 0 40px;
	// background-color: @grey-footer-background;
	color: @tan;
	line-height: 2.5em;
	text-transform: uppercase;
	.fs(20);
	
	a {
		color: @tan;
		// text-decoration: none;
	}
}

.para-back-common() {
	position: relative;
	overflow: hidden;
	
	.back {
		display: block;
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		right: 0;
		height: 125%;
		height: ~"calc(100% + 100px)";
		
		.full-back;
	}
}

.para-back(@picUrl) {
	.para-back-common;
	.back {
		background-image: url(@picUrl);
	}
}
.para-back-class(@class) {
	.para-back-common;
	.back {
		background-image: url(@picUrl);
	}
}

.parallax {
	.para-back-common;
}

.pre-transitions .smooth-in {
	opacity: 0;
	transform: translateX(-14px);
}
.transitions-on .now-visible .little-delay, .transitions-on .now-visible.little-delay {
	transition-delay: .7s;
}
.transitions-on .now-visible .smooth-in, .transitions-on .now-visible.smooth-in {
	transition: .7s;
	opacity: 1;
	transform: translateX(0);
}

a.body-color {
	color: @grey-body-text;
}