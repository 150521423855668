body.locked {
	overflow: hidden;
}
.location-topper {
	.fs(21);
	background-color: @tan;
	text-align      : center;
	line-height     : 3em;
	
	a {
		color: inherit;
	}
	.tablet-and-smaller({
		line-height: 1.3em;
		padding: 10px 40px;
	});

}

.top-menu {
	list-style: none;
	
	&:hover {
		li.current {
			border-color: transparent;
		}
	}

	li {
		display: inline-block;
		margin : auto 10px;
		
		border-bottom: solid 3px transparent;
		transition: border-color .3s;
		
		&.current {
			border-color: @tan;
		}
		
		&:hover {
			border-color: lighten(@tan, 10%);
			&.current {
				border-color: lighten(@tan, 10%);
			}
		}

		a {
			.cormorant;
			.fs(28);
			padding-bottom: 10px;
			font-weight    : 600;
			color          : white;
			text-decoration: none;
			padding        : 3px 6px;
			text-transform : uppercase;
		}
	}
}
.page-index {
	.site-header {
		position     : relative;
		margin-bottom: 70px;

		.brand {
			position  : absolute;
			top       : 20px;
			left      : 0;
			right     : 0;
			text-align: center;
			z-index   : 40;
			
			img {
				max-width: 85%;
			}
		}
		
		.top-menu {
			padding-left: 0;
		}


		.bottom {
			position  : absolute;
			bottom    : -30px;
			left      : 0;
			right     : 0;
			text-align: center;
			z-index   : 40;
			padding-top: 120px;

			p {
				.fs(52);
				.cormorant;
				color: white;

				strong {
					font-weight: 600;
				}

				em {
					font-weight: 300;
				}
			}

			.brown-button {
				.fs(39);
				.cormorant;
				text-transform: uppercase;
			}
		}
		
		.slide-msgs {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			
			.slide {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				opacity: 0;
				
				
				&.visible {
					opacity: 1;
				}
				
				&.invisible {
					opacity: 0;
				}
			}
			&.transitions-on .slide {
				transition: opacity .3s;
			}
		}
		
		.tablet-and-smaller({
			.slide {
				p {
					.fs(34);
				}
			}
			
			.top-menu {
				li {
					margin-bottom: 14px;
				}
			}

		});
		
		
	}
}
.tribar {
	display: inline-block;
	width: 30px;
	margin: 0px 10px 0 10px;
	cursor: pointer;
	// position: absolute;
	// top: 10px;
	// right: 10px;
	
	.one, .two, .three {
		border-bottom: solid 3px;
		border-color: white;
	}
	.two {
		margin: 7px 0;
	}
	
}

.cart {
	display: inline-block;
	position: relative;
	
	@box-size: 22px;
	
	.cart-item-count {
		position: absolute;
		right: -10px;
		bottom: -10px;
		border-radius: 50%;
		width: @box-size;
		height: @box-size;
		background-color: @tan;
		color: white;
		font-weight: 600;
		text-align: center;
	}
}
.page-inner {
	.site-header {
		display: grid;
		grid-template-columns: auto auto;
		padding: 20px;
		background-size: cover;

		.brand {
			align-self: center;
			
			& > * {
				vertical-align: middle;
			}
		}

		
		.other-links {
			align-self: center;
			text-align: right;
			
			& > * {
				vertical-align: middle
			}
			
			a {
				margin-left: 30px;
				text-decoration: none;
				.cormorant;
				text-transform: uppercase;
			}
		}
	}
	.tablet({
		.tribar {
			display: none;
		}
		nav {
			display: inline-block;
			.hidden-til-mobile {
				display: none;
			}
		}
	});
	
	.site-header + .container-wrapper {
		margin-top: 60px;
	}
	
	@mobile-nav-ruleset: {
		nav {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			width: 300px;
			margin-left: -300px;
			
			&.open {
				margin-left: 0;
			}
		}
		
		.other-links {
			.cart, .hollow-button, .facebook-link {
				display: none !important;
			}
		}
		
		nav {
			background-color: @tan;
			z-index: 20;
			.top-menu {
				padding-left: 0;
				li {
					display: block;
				}
			}
			.hidden-til-mobile {
				div, a {
					display: block;
				}
				a {
					padding-left: 15px;
				}
				
				.inner-header-facebook-picture img {
					width: 30px;
				}

			}
		}
	};
	.tablet-and-smaller(@mobile-nav-ruleset);
	
	&.page-show-artwork {
		.artwork-layout {
			.header {
				@mobile-nav-ruleset();
				
				.links {
					display: grid;
					min-height: 71px;
					
					.icons {
						padding-right: 25px;
					}
					
					.phone {
						color: black;
						text-decoration: none;
					}
					.desktop({
						grid-template-columns: auto auto;
						.icons {
							align-self: end;
							justify-self: end;
						}
					});
					
					.desktop-and-smaller({
						grid-template-columns: auto;
						row-gap: 10px;
						
						.icons {
							position: relative;

						}
						
						.cart {
							position: absolute;
							right: 25px;
							bottom: 100%;
						}
					});
				}
				
				.logo {
					text-decoration: none;
				}
				
				.cart { 
					padding-left: 20px;
					a {
						text-decoration: none;
					}
				}
				
				.breadcrumbs {
					line-height: 1.2em;
					padding-bottom: 10px;
					padding-top: 6px;
					min-height: 30px; 
				}

				
				.tribar {
					display: inline-block;
					position: absolute;
					top: 14px;
					right: 15px;
					& > * {
						border-color: black;
					}
				}

			}
		}
	}
}

.home-titles {
	h1 {
		.fs(66);
		color         : @tan;
		text-transform: uppercase;
		.cormorant;
		margin    : 0 auto;
		text-align: center;
	}

	h2 {
		.fs(25);
		text-align : center;
		margin     : 0 auto;
		font-weight: 400;
	}
}

.inner-titles {
	.home-titles;
	h1, h2 {
		text-align: left;
	}
}

.gallery-grid {
	column-gap           : 16px;
	row-gap              : 40px;
	margin-top           : 60px;
	
	
	.big-phone-and-smaller({
		.category-tile {
			display: block;
			margin: 20px auto;
		}
	});
	
	.big-phone({
		display              : grid;
		grid-template-columns: 1fr 1fr;
	});
	.tablet({
		grid-template-columns: 1fr 1fr 1fr;
	});

	.category-tile {
		.ratio(1.5);
		position        : relative;
		background-color: @tan;
		overflow: hidden;
		max-width: 400px;

		.img {
			position  : absolute;
			top       : 0;
			right     : 0;
			bottom    : 0;
			left      : 0;
			transition: opacity .3s;
			background-size: cover;
		}

		.label {
			padding-top     : 10px;
			padding-left    : 10px;
			position        : absolute;
			top 	        : 100%;
			left            : 0;
			width           : 85%;
			transform: translateY(-110px);
			transition      : transform .3s;

			background-color: white;
			
			display: grid;
			grid-template-rows: auto 1fr;


			.name {
				.cormorant;
				.fs(50);
				text-transform: uppercase;
				color: black;
				min-height: 2.5em;

			}

			.view {
				.fs(22);
				color         : darken(@brown, 20%);
				text-transform: uppercase;
			}
		}

		.desc {
			// position: relative;
			color: black;
			padding-right: 10px;
			min-height: 100px;
			.desc-content {
				// position: absolute;
				// top: 0;
				// left: 0;
				// right: 10px;
			}
		}

		&:hover {
			.img {
				opacity: .2;
			}

			.label {
				// padding-bottom: 180px;
				transform: translateY(-99%);
			}
		}
	}
}

footer {
	.fs(24);
	background-color: @grey-footer-background;
	padding-top     : 60px;
	padding-bottom  : 60px;
	color           : white;

	p:first-child {
		margin-top: 0;
	}
	
	.big-phone({
		.container {
			display              : grid;
			grid-template-columns: repeat(2, auto);
			column-gap           : 20px;
		}

	});

	.tablet({
		.container {
			grid-template-columns: repeat(4, auto);
		}

	});

	.contact {
		.star {
			.fs(34);
			position    : relative;
			color       : @tan;
			box-sizing  : border-box;
			padding-left: 50px;

			img {
				position   : absolute;
				margin-left: -50px;
			}
		}

		.phone {
			margin-bottom: 0;
			margin-top   : 24px;

			a {
				.fs(33);
				color          : @tan;
				text-decoration: none;
			}
		}

		.email {
			margin-top: 0;

			a {
				color          : @tan;
				text-decoration: none;
			}
		}
	}

	.hours {
		em {
			color       : @tan;
			font-style  : normal;
			padding-left: 15px;
		}

		.follow {
			margin-top: 40px;

			a {
				color          : white;
				text-decoration: none;

				img {
					padding-left: 15px;
				}
			}
		}
	}

	.footer-nav {
		.bottom-menu {
			list-style: none;
			margin    : 0;
			padding   : 0;

			li {
				margin: 10px 0;
			}

			a {
				color          : white;
				text-decoration: none;
				text-transform : uppercase;
			}
		}
	}

	.mark {
		text-align: center;

		.copyright {
			margin-top: 30px;
		}
	}

}

.address-with-marker {
	display: grid;
	grid-template-columns: auto 1fr;
	
	.marker {
		padding-right: 10px;
		padding-top: 3px;
	}
}

.contact-input-elements {
	label {
		display: block;
		margin-bottom: 12px;
	}
	
	input, textarea {
		box-sizing: border-box;
		margin-top: 5px;
		padding: 5px 10px;
		width: 100%;
		border: solid 1px @tan;
	}
	
	button {
		.tan-button;
		color: black;
		font-weight: 400;
		display: inline-block;
		margin-top: 20px;
	}

}

.contact-layout {
	.contact-input-elements;
	display: grid;
	column-gap: 50px;
	row-gap: 50px;
	margin: 50px auto;
	
	
	.tablet({
		grid-template-columns: auto 1fr;

	});
	
	& > * > p:first-of-type, & > * > h2:first-child {
		margin-top: 0;
	}
	
	h2 {
		.cormorant;
		.fs(49);
		text-transform: uppercase;
	}
	.msg {
	}
	
	.hours {
		width: 300px;
		
		
		em {
			font-style: normal;
			color: @tan;
			padding-left: 10px;
		}
	}
	
	form {
		margin-top: 30px;
	}

}
.about-layout {
	display: grid;
	column-gap: 50px;
	row-gap: 50px;
	margin: 50px auto;
	.tablet({
		grid-template-columns: 1fr auto;

	});
	
	& > * > p:first-of-type, & > * > h2:first-child {
		margin-top: 0;
	}
	
	.msg {
		.about-photo-inset-picture {
			float: left;
			padding-right: 20px;
			padding-bottom: 20px;
		}
	}
	
	.hours {
		max-width: 300px;
		
		h2 {
			.cormorant;
			.fs(49);
			text-transform: uppercase;
		}
		
		em {
			font-style: normal;
			color: @tan;
			padding-left: 10px;
		}
	}
}

.page-contact {
	.location-map-picture img {
		display: block;
		width: 100%;
	}

	
	.attractions-bar {
		display: grid;
		column-gap: 20px;
		grid-template-columns: 5fr 3fr;
		margin-top: 20px;
		margin-bottom: 40px;
		.tablet-and-smaller({
			grid-template-columns: auto;
		});
		
		.attractions {
			padding: 20px;
			background-color: @grey-box-background;
			z-index: 4;
			h2 {
				.cormorant;
			}
			
			ul {
				list-style: none;
				font-weight: 600;
				padding-left: 0;
				li {
					margin-bottom: 5px;
				}
			}
			
			.key {
				.item {
					display: inline-block;
					font-weight: 600;
					margin-right: 15px;
					.box {
						display: inline-block;
						width: 20px;
						height: 20px;
						border: solid 1px black;
						vertical-align: middle;
						margin-right: 3px;
						
						&.grey {
							background-color: #d6d6d6;
						}
						
						&.blue {
							background-color: #d8f6f5;
						}
						
						&.green {
							background-color: #dcebcc;
						}
					}
				}
			}
		}
		
		.map {
			position: relative;
			img {
				max-width: 100%;
			}
			
			.red-arrow-picture {
				img {
					position: absolute;
					top: 54%;
					left: 48%;
				}
			}
			
			.tablet-and-smaller({
				.red-arrow-picture {
					img {
						display: none;
					}
				}
			});
		}
	}
}

.join-mailing-list {
	padding: 10px;
	text-align: center;
	h2 {
		.cormorant;
		.fs(49);
		text-transform: uppercase;
		color: white;
		margin-bottom: 0;
	}
	p {
		color: white;
		margin-bottom: 30px;
	}
	
	.content {
		margin: 50px auto;
		max-width: 700px;
	}
	
	form {
		display: grid;
		grid-template-columns: 1fr auto;
		column-gap: 10px;
		
		input {
			width: 100%;
			background-color: white;
			box-sizing: border-box;
			padding: 8px 16px;
			border: 0;
		}
		button {
			.tan-button;
			color: black;
			font-weight: 400;
		}
	}
	a {
		color: white;
	}
}


.galleries-strip {

	background-color: @galleries-strip-back;
	column-gap: 2px;
	border-top: 4px solid @tan;
	border-bottom: 4px solid @tan;
	.small-tablet({
		display: flex;
	});
	
	.category-cell {
		background-color: @tan;
		cursor: pointer;
		position: relative;
		.small-tablet({
			flex-grow: 1;
			display: flex;
			align-items: center;
			min-height: 80px;
		});
	
		
		&:hover {
			background-color: lighten(@brown, 10%);
		}
		
		&.current {
			background-color: @grey-body-text;
			color: white;
		}

		.name {
			.cormorant;
			.fs(39);
			width: 100%;
			text-align: center;
			font-weight: 600;
		}
		
		&.stacked {
			display: grid;
			grid-template-columns: auto;
			
			&:hover {
				background-color: @tan;
			}

			.name {
				width: auto;
				display: inline-block;
				padding: 3px 5px;
				align-self: center;
			}
			.types {
				display: grid;
				grid-template-columns: 1fr;
				row-gap: 1px;
				background-color: white;
				.name {
					text-align: center;
					background-color: @tan;
					&:hover {
						background-color: lighten(@brown, 10%);
					}
					
					&.current {
						background-color: @grey-body-text;
						color: white;

					}
				}
			}

		}
		.secondary-categories {
			position: absolute;
			top: 100%;
			left: 0;
			width: 300px;
			background-color: fade(@drop-down-tan, 90%);
			display: none;
			z-index: 20;
			
			.secondary-category-cell {
				padding: 5px 20px;
				.cormorant;
				.fs(30);
				color: black;
				font-weight: 600;
				text-decoration: none;;
				text-transform: capitalize;
				&.current {
					background-color: fade(@drop-down-selected-tan, 40%);
				}
				
				&:hover {
					background-color: fade(@drop-down-selected-tan, 80%);
				}
			}
		}
		
		&:hover {
			.secondary-categories {
				display: block;
			}
		}
		
	}
	.small-tablet-and-smaller({
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		.category-cell.stacked {
			display: contents;
			.types {
				display: contents;
			}
			.name {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	});
	.big-phone-and-smaller({
		grid-template-columns: repeat(2, 1fr);
	});
	.phone-and-smaller({
		display: block;
		grid-template-columns: repeat(1, 1fr);
		background-color: @tan;
		text-align: center;
		.category-cell {
			display: inline-block;
			margin-left: 10px;
			margin-right: 10px;
			padding-left: 2px; 
			padding-right: 2px;
			&:hover {
				.secondary-category-cell {
					display: none;
				}
			}
		}
	});
}

.section-titles {
	h2 {
		.cormorant;
		text-align: left;
		.fs(49);
	}

	h3 {
		.cormorant;
		text-align: center;
		.fs(49);
	}
}

.artwork-tiles {
	// display: grid;
	// grid-template-columns: repeat(2, 1fr);
	margin-top: 40px;
	overflow-x: hidden;
	font-size: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.artwork-tile {
		// .ratio(1);
		display: inline-block;
		height: 300px;
		background-position: center center;
		background-size: cover;
		text-decoration: none;
		position: relative;
		isolation: isolate;
		overflow: hidden;
		flex-grow: 1;
		max-width: 33.3333%;
		
		.big-phone-and-smaller({
			max-width: 50%;
			height: 200px;
		});
		
		.bg {
			// position: absolute;
			// width: 100%;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
			z-index: -1;
			user-select: none;
		}
		
		
		.mask {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			transition: opacity .3s;
			opacity: 0;
			background-color: fade(black, 50%);
			color: white;
			display: flex;
			padding: 20px;
			align-items: flex-end;
			
			.msg {
				
				.name {
					.fs(28);
					display: block;
					.cormorant;
				}
				
				.button {
					// display: inline-block;
					// display: none;
					.fs(24);
					background-color: @tan;
					color: black;
					margin-top: 10px;
					padding: 4px 8px;
					text-transform: uppercase;
				}
			}
		}
		
		&:hover {
			.mask {
				opacity: 1;
			}
		}
	}
	
	&.big-show {
		// grid-template-columns: repeat(2, 1fr);
	}
	
	.big-phone({
		// grid-template-columns: repeat(3, 1fr);
		
		&.big-show {
			// grid-template-columns: repeat(3, 1fr);
		}
	});
	.tablet({
		// grid-template-columns: repeat(4, 1fr);
		
		&.big-show {
			// grid-template-columns: repeat(3, 1fr);
		}
		.artwork-tile .mask .msg {
			.name {
				.fs(28);
			}
		}
	});
	
	.desktop({
		// grid-template-columns: repeat(6, 1fr);
		.artwork-tile .mask .msg {
			
			.name {
				.fs(34);
			}
			.button {
				display: inline-block;
			}
		}
		&.big-show {
			// grid-template-columns: repeat(3, 1fr);
		}
	});
	
	
}

.section-titles {
	.sticker {
		position: relative;
		z-index: 10;
	}
	.filter-row {
		display: grid;
		grid-template-columns: auto auto 1fr;
		align-items: center;
		column-gap: 30px;
		margin: 10px 30px;
		
		.sorting {
			h2 {
				.cormorant;
				display: inline-block;
				padding-right: 15px;
			}
			.sort-options {
				display: inline-block;
			}
			label {
				display: inline-block;
				padding-right: 20px;
			}
		}
		.cart {
			text-align: right;
		}
		
		.search-list {
			position: relative;
			align-self: center;
			
			input {
				.ready-to-load-img .search-icon-picture-background;
				width: 450px;
				border: 0;
				border-bottom: solid 1px @tan;
				
				background-position: 3px center;
				background-repeat: no-repeat;
				background-size: auto 80%;
				padding: 5px 10px 5px 35px;
			}
			button {
				border: 0;
				background-color: transparent;
				
				position: absolute;
				right: 5px;
				bottom: 5px;
			}
		}

		
		.tablet-and-smaller({
			grid-template-columns: auto; 
			.search-list {
				input {
					width: 100%;
					max-width: 450px;
					box-sizing: border-box;
				}
			}
			
			.sorting {
				margin-bottom: 12px;
				h2 {
					margin: 0;
				}
				
				label {
					.fs(16);
				}
			}

		});
		.phone-and-smaller({
			.sorting {
				h2 {
					margin: 0;
					float:left;
				}
				
				.sort-options {
					float: right;
					display: grid;
					grid-template-columns: 1fr 1fr;
					row-gap: 10px;
					// label {
					// 	float: left;
					// }
				}
			}

		})
	}
	
	.product-details {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #eeeeee;
		z-index: 3;
		display: flex;
		align-content: center;
		justify-content: center;
		align-items: center;
		padding: 20px;
		.fs(30);
		text-align: center;
		
		
		h2 {
			text-align: center;
			margin-bottom: 0;
		}
		.artist {
			color: @tan;
		}
	}
	
	&.stuck {
		padding-top: 147px;

		.sticker {
			position: fixed;
			top: 51px;
			left: 0;
			right: 0;
			z-index: 10;
			background-color: white;
			
			.filter-row {
				.sorting {
					h2 {
						margin-top: 0;
						margin-bottom: 0;
					}
				}
			}
			
			.tablet-and-smaller({
				top: 49px;
			});
		}
	}
}

.page-gallery, .page-gifts {
	.site-header {
		&.stuck {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			z-index: 12;
			padding: 4px 20px;
			
			.brand {
				.inner-header-logo-picture {
					img {
						width: 120px;
					}

				}
			}
			
			.top-menu {
				margin-top: 14px;
    			margin-bottom: 4px;
			}
		}
	}
}
.galleries-list {
	margin-top: 22px;
	
	.home-titles {
		margin-bottom: 20px;
	}
}

.vid-stripe {
	font-size: 0;
	.tablet({
		margin-top: 40px;
		.ratio(0.219);

	});
	position: relative;
	.vid-cell {
		.fs(20);
		.ratio(9 / 16);
		overflow: hidden;
		position: relative;
		transition: width .3s;
		
		
		&:after {
			content: '\00a0';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background-color: fade(@tan, 70%);
			background-image: url('/img/play-icon.png');
			background-position: center center;
			background-repeat: no-repeat;
		}
		
		video {
			position: absolute;
			top: 0;
			bottom: 0;
			height: 100%;
		}

		&.current {
			&:after {
				display: none;
			}
			video {
				left: 0;
			}
		}
	}
	.tablet({
		.cell-holder {
			display: flex;
			justify-content: space-between;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		.vid-cell {
			height: 100%;
			width: 20%;
			&.current {
				width: 39%;
			}
			
			video {
				left: -25%;
				transition: left .3s;

			}
		}

	});
	.tablet-and-smaller({
		.cell-holder {
			margin-bottom: 30px;
		}
		.vid-cell {
			max-width: 400px;
			margin: 10px auto;
		}
	});
}

.page-gifts {
	.galleries-strip {
		display: none;
	}
}

.page-portal {
	.tan-button {
		color: black;
		font-weight: 400;
	}
	
	.portal-grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		column-gap: 50px;
		margin-top: 50px;
		margin-bottom: 80px;
		
		.portal {
			border: solid 1px @tan;
			padding: 40px 60px 60px 40px;
			position: relative;
			h2 {
				.cormorant;
			}
			
			.tan-button {
				position: absolute;
				bottom: -40px;
				left: 40px;
				transform: translateY(-50%);
			}
		}
	}
	
	.questions {
		margin-top: 40px;
		margin-bottom: 60px;
		h2 {
			color: @tan;
			.cormorant;
			.fs(50);
			margin-bottom: 0;
		}
		h3 {
			color: @tan;
			margin-top: 0;
			margin-bottom: 0;
			font-weight: 400;
		}
		a {
			color: @tan;
			text-decoration: none;
		}
	}
}

.map-with-street-view {
	display: grid;
	grid-template-columns: 1fr 3fr;
	column-gap: 20px;
	
	.street {
		position: relative;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.img {
		img {
			width: 100%;
		}
	}
}


.portal-menu {
	border-bottom: solid 2px black;
	
	a {
		display: inline-block;
		background-color: @grey-box-background;
		padding: 8px 20px;
		text-decoration: none;
		color: @grey-body-text;
		
		img {
			vertical-align: middle;
			padding-left: 10px;
		}
		
		
		&.current {
			background-color: lighten(@tan, 20%);
		}
	}
}
.current-artist-picture {
	max-width: 100%;
}

.artist-submission-layout {
	max-width: 800px;
	
	input[type=text], input[type=date], textarea {
		display: block;
		margin-top: 5px;
		width: 100%;
		box-sizing: border-box;
		border: solid 1px @grey-footer-background;
		border-radius: 3px;
	}
	
	label {
		display: block;
		margin-top: 12px;
	}
}

.artist-info {
	display              : grid;
	grid-template-columns: 3fr 2fr;
	margin-top           : 30px;
	column-gap           : 50px;
	
	&.balanced {
		grid-template-columns: 1fr 1fr;
	}

	.info-section {

		h2 {
			color      : @tan;
			font-weight: 400;
		}

		.content-box {
			border-top : solid 2px @tan;
			padding-top: 10px;

			.contact-box {
				grid-template-columns: 3fr 2fr;

				&.even {
					grid-template-columns: 1fr 1fr;
				}
			}

			input,
			textarea {
				background-color: @grey-input-back;
			}
		}

		.content-box+.content-box {
			margin-top: 30px;
		}
	}

}
.photo-details-dialog.dialog-frame {
	.head {
		display: none;
	}
	
	.btn-close {
		color: white;
		right: -30px !important;
		top: -40px !important;
		.fs(40);
	}
	
	img {
		max-width: 100%;
		display: block;
	}
	
	.foot {
		border-top: 0 !important;
		padding: 0px !important;
		button {
			display: none;
		}
	}
	
	
	.the-dialog {
		overflow: visible;
		border-radius: 0px;
		.body {
			padding: 0;
		}
	}
	
	.right img {
		position: absolute;
		top: 50%;
		right: 40px;
		height: 60px;
		transform: translateY(-50%);
	}
	.left img {
		position: absolute;
		top: 50%;
		left: 40px;
		height: 60px;
		transform: translateY(-50%);
	}
}

.page-cart, .page-receipt {
	
	.inner-titles {
		h1 {
			display: inline-block;
		}
		a {
			display: inline-block;
			margin-left: 30px;
			color: @blue-text;
			text-decoration: none;
		}
	}
	table.items {
		width: 100%;
		td {
			padding: 3px;
		}
		.msg {
			width: 90%;
			padding-left: 20px;
		}
		
		.actions {
			button {
				border: 0;
				background-color: transparent;
				padding: 0;
				color: @tan;
			}
			form {
			}
			
			.update-cart-quantity {
				margin-bottom: 10px;
				input {
					width: 40px;

				}
			}
		}
		.price-reason {
			white-space: nowrap;
			text-align: right;
			padding-right: 15px;
		}
	}
	.product-thumbnail {
		width: 100px;
		
	}
	
	table.totals {
		width: 100%;
		td:last-child {
			text-align: right;
		}
		td:first-child {
			font-weight: 600;
			padding-left: 127px;
			padding-bottom: 10px;
			text-align: left;
		}
	}
	
	.shipping-options {
		margin-bottom: 30px;
		label {
			padding-right: 15px;
		}
		
		.big-phone-and-smaller({
			label {
				display: block;
			}
		});
	}
	
	.shipping-location {
		margin-bottom: 30px;
		&.hidden {
			display: none;
		}
		label {
			margin-bottom: 0;
		}
	}
	
	.checkout-button:disabled {
		background-color: @grey-input-back;
	}

}

.page-receipt {
	table.items {
		.actions {
			display: none;
		}
	}
}

.page-checkout {

	#payment-form {
		margin-top: 20px;
		&.submitting {
			input, button {
				opacity: .2;
			}
		}
		
		button {
			background-color: @blue-text;
			margin-top: 10px;
			border: 0;
			color: white;
		}

		button:disabled {
			background-color: @grey-box-background;
		}
	}
}

.add-to-cart {
	&.added {
		button {
			background-color: @grey-box-border;
		}
	}
}

#add-to-cart-response {
	margin-right: 10px;
	& > * {
		display: inline-block;
		font-weight: 600;
		vertical-align: middle;
	}
	.tan-link {
		margin-left: 20px;
	}
}